import React from 'react';
import FancyLink from '../components/molecules/FancyLink';
import Page from '../components/layouts/Page';
import Sidecard from '../components/molecules/Sidecard';
import { graphql } from 'gatsby';
import Breadcrumb from '../components/molecules/Breadcrumb';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { BreadcrumbJsonLd, ArticleJsonLd } from 'gatsby-plugin-next-seo';

export default function newsItem({ data, location }) {
  const content = data.wpPost;

  let desc = content.excerpt;
  let strippedDesc = desc.replace(/(<([^>]+)>)/gi, '');

  return (
    <>
      <GatsbySeo
        title={content.title}
        description={content.excerpt}
        openGraph={{
          type: 'website',
          url: 'https://graafdaron.nl/nieuws/' + content.slug,
          title: content.title + ' | Scouting Graaf Daron',
          description: strippedDesc,
          //opengraph image
          images: [
            {
              url: content.featuredImage.node.localFile.url,
              width: 800,
              height: 600,
              alt: content.title + ' | Scouting Graaf Daron afbeelding',
            },
          ],
        }}
      />
      <BreadcrumbJsonLd
        itemListElements={[
          {
            position: 1,
            name: 'Home',
            item: 'https://graafdaron.nl',
          },
          {
            position: 2,
            name: 'Nieuws',
            item: '#',
          },
          {
            position: 3,
            name: content.title,
            item: 'https://graafdaron.nl/' + content.slug,
          },
        ]}
      />
      <ArticleJsonLd
        url={`https://graafdaron.nl/nieuws/` + content.slug}
        headline={content.title + ' | Scouting Graaf Daron'}
        datePublished={content.date}
        dateModified={content.modified}
        authorName="Scouting Graaf Daron"
        publisherName="Scouting Graaf Daron"
        publisherLogo="/android-chrome-512x512.png"
        description={content.excerpt}
        overrides={{
          '@type': 'BlogPosting', // set's this as a blog post.
        }}
      />
      <Page
        breadcrumbs={
          <>
            <Breadcrumb title="Home" link="/" />
            <Breadcrumb title="Nieuws" link="/nieuws" />
            <Breadcrumb
              title={content.title}
              link={`/nieuws/${content.slug}`}
            />
          </>
        }
        sidebar={true}
        title={content.title}
        html={content.content}
        aside={
          content.blogs.sidebar &&
          content.blogs.sidebar.map((bar) => {
            if (bar.contact) {
              return (
                <Sidecard title="Contactgegevens">
                  {data.wp?.aparteInstellingen.globalSettings.contactData.map(
                    (contact) => {
                      return (
                        <div className="mb-4">
                          <div className="flex items-center flex-nowrap">
                            <img
                              className="mr-3"
                              src={contact.icon.localFile.url}
                              alt="iconContact"
                            />
                            <a
                              href={contact.link.url}
                              className="underline font-body"
                            >
                              {contact.link.title}
                            </a>
                          </div>
                          {contact.subtext && (
                            <span className="block ml-8 text-sm font-body">
                              *{contact.subtext}
                            </span>
                          )}
                        </div>
                      );
                    },
                  )}
                </Sidecard>
              );
            } else if (bar.socialmedia) {
              return (
                <Sidecard title="Deel dit artikel">
                  <FancyLink
                    link={`https://www.facebook.com/sharer/sharer.php?u=${
                      location.href ?? ''
                    }`}
                    target="_blank"
                    title="Facebook"
                  />
                  <FancyLink
                    link={`https://twitter.com/intent/tweet?text=${
                      location.href ?? ''
                    }`}
                    target="_blank"
                    title="Twitter"
                  />
                  <FancyLink
                    link={`https://api.whatsapp.com/send?text=${
                      location.href ?? ''
                    }`}
                    target="_blank"
                    title="WhatsApp"
                  />
                </Sidecard>
              );
            } else {
              return (
                <Sidecard title={bar.sidebarItem.title}>
                  {bar.linksOrText
                    ? bar.sidebarItem.links.map((link) => {
                        return (
                          <FancyLink
                            link={link.link.url}
                            target={link.link.target}
                            title={link.link.title}
                          />
                        );
                      })
                    : bar.sidebarItem.text}
                </Sidecard>
              );
            }
          })
        }
      />
    </>
  );
}

export const query = graphql`
  query newsItem($slug: String) {
    wpPost(slug: { eq: $slug }) {
      title
      slug
      content
      excerpt
      blogs {
        sidebar {
          linksOrText
          sidebarItem {
            links {
              link {
                target
                title
                url
              }
            }
            text
            title
          }
          contact
          socialmedia
        }
      }
      featuredImage {
        node {
          localFile {
            url
          }
        }
      }
      modified
      date
    }
  }
`;
